<template>
    <div class="container-fluid">
        <b-row>
            <b-col lg="12">
                <card class=" card-block card-stretch">
                    <template v-slot:headerTitle>
                        <h4 class="card-title mb-0">Resultados {{ intelinsuranceApi.user.company }} - Benchmarking</h4>
                    </template>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="2">
                                <div class="form-group ">
                                    <label for="model">Fecha Bench</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'fecha')">
                                        <!--option value="may">Mayo</option>
                                        <option value="june">Junio</option>
                                        <option value="july">Julio</option>
                                        <option value="september">Septiembre</option>
                                        <option value="october">Octubre</option>
                                        <option value="november">Noviembre</option>
                                        <option value="dicember">Diciembre</option>
                                        <option value="january-23">Enero '23</option>
                                        <option value="february-23">Febrero '23</option>
                                        <option value="march-23">Marzo '23</option>
                                        <option value="april-23">Abril '23</option>
                                        <option value="may-23">Mayo '23</option-->
                                        <option value="june-23">Junio '23</option>
                                        <option value="july-23">Julio '23</option>
                                        <option value="august-23">Agosto '23</option>
                                        <option value="september-23">Septiembre '23</option>
                                        <option value="october-23">Octubre '23</option>
                                        <option value="november-23">Noviembre '23</option>
                                        <option value="dicember-23">Diciembre '23</option>
                                        <option value="january-24">Enero '24</option>
                                        <option value="february-24">Febrero '24</option>
                                        <option value="march-24">Marzo '24</option>
                                        <option value="april-24">Abril '24</option>
                                        <option value="may-24">Mayo '24</option>
                                        <option value="june-24">Junio '24</option>
                                        <option value="july-24">Julio '24</option>
                                        <option value="august-24">Agosto '24</option>
                                        <option value="september-24">Septiembre '24</option>
                                        <option value="october-24">Octubre '24</option>
                                        <option value="november-24">Noviembre '24</option>
                                        <option value="dicember-24">Diciembre '24</option>

                                        <!--option value="august">Agosto</option-->
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="2">
                                <div class="form-group ">
                                    <label for="model">Pago</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'payment')">
                                        <option value="-">Seleccione</option>
                                        <option value="1">Efectivo</option>
                                        <option value="2">Tarjeta</option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="2">
                                <div class="form-group ">
                                    <label for="model">Localidades</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'place')">
                                        <option value="-">Seleccione</option>
                                        <option v-for="place in configuration_places" :value="place.id" :key="place.id">
                                            {{ place.city }}
                                        </option>
                                    </select>
                                    <!-- <treeselect class="form-control form-multiple mb-3"
                                        @input="setResultsFilter($event, 'place')" :multiple="false"
                                        :options="configuration_places" /> -->
                                </div>
                            </b-col>
                            <b-col lg="2">
                                <div class="form-group">
                                    <label for="model">Autos</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'car')">
                                        <option value="-">Seleccione</option>
                                        <option v-for="car in configuration_cars" :value="car.id" :key="car.id"
                                            :text="car.brand + ' ' + car.model + ' ' + car.year"
                                            :data-infoauto="car.infoauto" :data-year="car.year">
                                            {{ car.brand }} {{ car.model }} {{ car.year }}
                                        </option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="2">
                                <!--div class="form-group">
                                <label for="model">Compañias</label>
                                <select class="form-control mb-3"
                                id="configuration_list"
                                name="configuration_list"
                                @change="setResultsFilter($event, 'company')"
                                >
                                    <option value="-">Seleccione</option>
                                    <option v-for="car in configuration_cars" :value="car.id" :key="car.id">
                                    {{ car.brand }} {{ car.model }} {{ car.year }}
                                    </option>
                                </select>
                            </div-->

                                <div class="form-group">
                                    <label for="model">Cobertura</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'cobertura')">
                                        <option value="-">Seleccione</option>
                                        <option value="A" v-if="intelinsuranceApi.user.company != 'Experta'">
                                            Responsabilidad Civil</option>
                                        <option value="B" v-if="intelinsuranceApi.user.company != 'Experta'">Terceros
                                            Totales</option>
                                        <option value="C Base" v-if="intelinsuranceApi.user.company != 'Experta'">
                                            Terceros Completos Base</option>
                                        <option value="C Full">Terceros Completos Full</option>
                                        <option value="D1">Todo Riesgo F. Alta</option>
                                        <option value="D" v-if="intelinsuranceApi.user.company != 'Experta'">Todo Riesgo
                                            F. Baja</option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="2">
                                <div class="form-group">
                                    <label for="model" style="min-height:24px; width:100%;"></label>
                                    <button type="submit" class="btn btn-primary"
                                        @click="getResultsFilter()">Filtrar</button>
                                </div>
                            </b-col>
                        </b-row>
                        <div class="table-responsive data-table">
                            <div class="buttons col-12"></div>
                            <table class="data-tables table" id="datatable" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Compañía</th>
                                        <th>Cobertura</th>
                                        <th>Grupo</th>
                                        <th>Variación</th>
                                        <th>Suma</th>
                                        <th>Facturación</th>
                                        <th>Pago</th>
                                        <th>Localidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="quote in configuration_result" :key="quote.id"
                                        :id="'contact-' + quote.id">
                                        <!--td>
                                    <img src="../../../assets/images/user/01.jpg"  class="rounded avatar-40 img-fluid" alt="">
                                 </td-->
                                        <td>{{ quote.company_name }}</td>
                                        <td>{{ quote.name }} <span
                                                v-if="quote.group_assigned == 'D1' || quote.group_assigned == 'D'"
                                                style="" class="badge badge-info">{{ quote.franchise_amount }}</span>
                                        </td>
                                        <td>{{ quote.group_assigned }}</td>
                                        <td v-if="quote.indice_premio > 1" class="greater">+{{ ((quote.indice_premio *
                                            100) - 100).toFixed(0) }}%</td>
                                        <td v-if="quote.indice_premio == 1" class="equal">{{ ((quote.indice_premio *
                                            100)
                                            - 100).toFixed(0) }}%</td>
                                        <td v-if="quote.indice_premio < 1" class="cheaper">{{ ((quote.indice_premio *
                                            100) - 100).toFixed(0) }}%</td>
                                        <td>{{ quote.suma_asegurada }}</td>
                                        <td>{{ quote.facturacion }}</td>
                                        <!--td><span v-if="quote.franchise_type != ''">{{quote.franchise}}</span></td-->
                                        <td>{{ quote.payment_name }}</td>
                                        <td>{{ quote.city }}</td>
                                        <!--td>
                                    <div class="d-flex align-items-center list-action">
                                       <a class="badge bg-success-light mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="View"
                                          :href="'/agenda/contacto/editar/'+contact.id"><i class="lar la-eye"></i></a>
                                       <a class="badge bg-danger-light mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"
                                          href="#"  @click="remove(contact.id)"><i class="far fa-times-circle"></i></a>
                                    </div>
                                 </td-->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <b-row v-if="getResultados">
                            <b-col lg="12" v-for="(item, index) in chart" :key="componentKey">
                                <card>
                                    <template v-slot:headerTitle>
                                        <h4>{{ item.title }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <p style="font-size: 12px;">{{ item.desc }}</p>
                                        <ApexChart :element="item.type" :chartOption="item.bodyData" />
                                    </template>
                                </card>
                            </b-col>
                        </b-row>

                    </template>
                </card>
            </b-col>
            <!--b-col sm="2" lg="2" class="wookie-col-2">
               <img src="@/assets/images/logo-wookie-alt-white.png" alt="">
            </b-col-->
        </b-row>
    </div>
</template>
<script>
import { core } from '../../../config/pluginInit'
import { mapGetters, mapState } from 'vuex'
import * as am4core from '@amcharts/amcharts4/core'
// import  AmCharts  from "../../../components/charts/AmChart";
import ApexChart from "../../../components/charts/ApexChart";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import store from "@/store/index";
export default {
    components: {
        // AmCharts,
        ApexChart,
        Treeselect
    },
    data() {
        return {
            today: '',
            contactList: [''],
            configuration_list: [],
            configuration_places: [],
            configuration_cars: [],
            configuration_result: [],
            configuration_result_historical: [],
            quotes_statistics_companies: [],
            quotes_statistics_values: [],
            car_history: [],
            ipc_history: [
                { 'month': "9", 'amount': 1.1207, 'year': '2023', accumulates: 12.07 },
                { 'month': "10", 'amount': 1.0830, 'year': '2023', accumulates: 20.37 },
                { 'month': "11", 'amount': 1.1280, 'year': '2023', accumulates: 33.17 },
                { 'month': "12", 'amount': 1.2550, 'year': '2023', accumulates: 58.67 },
                { 'month': "1", 'amount': 1.2060, 'year': '2024', accumulates: 79.27 },
                { 'month': "2", 'amount': 1.1320, 'year': '2024', accumulates: 92.47 },
                { 'month': "3", 'amount': 1.1100, 'year': '2024', accumulates: 103.47 },
                { 'month': "4", 'amount': 1.0880, 'year': '2024', accumulates: 111.55 },
                { 'month': "5", 'amount': 1.0420, 'year': '2024', accumulates: 115.75 },
                { 'month': "6", 'amount': 1.0460, 'year': '2024', accumulates: 115.75 },
                { 'month': "7", 'amount': 1.0400, 'year': '2024', accumulates: 115.75 },
                { 'month': "8", 'amount': 1.0420, 'year': '2024', accumulates: 115.75 },
                { 'month': "9", 'amount': 1.0350, 'year': '2024', accumulates: 115.75 },
                { 'month': "10", 'amount': 1.0320, 'year': '2024', accumulates: 115.75 },
                { 'month': "11", 'amount': 1.0240, 'year': '2024', accumulates: 115.75 },
                // { 'month': "12", 'amount': 1.0350, 'year': '2024', accumulates: 115.75 },
            ],
            car_result_history: [],
            selectedItems: [],
            dataTable: '',
            deleteWarning: 'Eliminando elementos...',
            doneDelete: 0,
            isSelectAll: false,
            getResultados: false,
            filterCompany: '',
            filterPlace: '',
            filterCar: '',
            filterCarName: '',
            filterQuoteGroup: '',
            filterBenchDate: '',
            filterPayment: '',
            dataFilter: {},
            componentKey: 10,
            chart: [{
                title: "Evolución de costo mensual",
                desc: "El presente gráfico muestra la evolución del premio mensual de los últimos 5 meses. ",
                type: 'line',
                componentKey: 10,
                id: "Zoomable on Value Axis",
                bodyData: {
                    color: "#0084ff",
                    markers: {
                        size: 5,
                    },
                    // fill: "#fdd400",
                    fill: {
                        type: 'solid'
                    },
                    chart: {
                        height: 500
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [6, 6, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                        curve: 'smooth',
                        dashArray: [8, 5]
                    },
                    colors: ['#0084ff', '#00ca00', '#ca0097', '#cab900', '#ca0000', '#4788ff', '#81ff84', '#ff0000', '#1cbdff', '#fe721c', '#43d396', '#535f6b'],
                    series: [{
                        name: 'Compañías',
                        data: [31, 40, 28, 51, 42, 109, 100]
                    }, {
                        name: 'Compañías',
                        data: [11, 32, 45, 32, 34, 52, 41]
                    }],
                    xaxis: {
                        type: 'category',
                        categories: ['Nov 23', 'Dic 23', 'Ene 24', 'Feb 24']
                    },
                    // tooltip: {
                    //   x: {
                    //     format: 'dd/MM/yy HH:mm'
                    //   }
                    // }
                },
            }],
        }
    },
    name: 'ResultsFilter',
    computed: {
        ...mapState(["productores", "infoauto", "procloud", "intelinsuranceApi"])
    },
    mounted() {


        console.log(this.intelinsuranceApi.user.configuration_id);
        console.log(this.intelinsuranceApi.user.company);
        this.getNow()
        store
            .dispatch("procloud/fetchConfigurationList")
            .then(() => {
                this.configuration_list = this.procloud.benchkmarking_configuration_list
            });



        store
            .dispatch("procloud/fetchConfigurationPlaces", this.intelinsuranceApi.user.configuration_id)
            .then(() => {
                this.configuration_places = this.procloud.benchkmarking_configuration_places
            });
        store
            .dispatch("procloud/fetchConfigurationData", this.intelinsuranceApi.user.configuration_id)
            .then(() => {
                this.configuration_places = this.procloud.benchkmarking_configuration_data.places
                this.configuration_cars = this.procloud.benchkmarking_configuration_data.cars
                this.configuration_companies = this.procloud.benchkmarking_configuration_data.companies
                //console.log(this.procloud.benchkmarking_configuration_data);
                //this.configuration_data = this.procloud.benchkmarking_configuration_data
            });

        //this.list()

    },
    beforeMount() {
        $('body').attr('id', "list-result");
    },
    destroyed() {
        //core.initDataTable()
    },
    methods: {
        getNow: function () {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            this.today = date;
        },
        setResultsFilter: function (event, type) {

            this.dataFilter = {};



            if (type == 'companies') {
                this.filterCompany = event.target.value;
            }
            if (type == 'place') {
                this.filterPlace = event.target.value;
            }
            if (type == 'car') {
                const infoauto = event.target.selectedOptions[0].getAttribute('data-infoauto');
                const carYear = event.target.selectedOptions[0].getAttribute('data-year');
                this.filterCarInfoauto = infoauto;
                this.filterCarYear = carYear;
                this.filterCar = event.target.value;
                this.filterCarName = event.target.value.name;
            }
            if (type == 'cobertura') {
                this.filterQuoteGroup = event.target.value;
            }
            if (type == 'fecha') {
                this.filterBenchDate = event.target.value;
            }
            if (type == 'payment') {
                this.filterPayment = event.target.value;
            }

            if (this.filterCompany != '') this.dataFilter.company = this.filterCompany
            if (this.filterPlace != '') this.dataFilter.location = this.filterPlace
            if (this.filterCar != '') this.dataFilter.car = this.filterCar
            if (this.filterCarInfoauto != '') this.dataFilter.infoauto = this.filterCarInfoauto
            if (this.filterCarYear != '') this.dataFilter.carYear = this.filterCarYear
            if (this.filterQuoteGroup != '') this.dataFilter.grupo = this.filterQuoteGroup
            if (this.filterBenchDate != '') this.dataFilter.fecha = this.filterBenchDate
            if (this.filterPayment != '') this.dataFilter.payment = this.filterPayment

            //this.dataFilter.payment = 123
            this.dataFilter.configurationId = this.intelinsuranceApi.user.configuration_id
            this.dataFilter.companyReference = this.intelinsuranceApi.user.company

            console.log(this.dataFilter);

        },
        getResultsFilter: function (event, type) {

            this.configuration_result = '';

            store
                .dispatch("procloud/fetchConfigurationResult", this.dataFilter)
                .then(() => {
                    this.configuration_result = this.procloud.benchkmarking_configuration_result;

                    // if (this.intelinsuranceApi.user.company == 'Sancor Seguros' || this.intelinsuranceApi.user.company == 'Allianz') {
                    // this.getIndiceByQuotes()
                    this.getHistorical()
                    // }
                });
        },
        getIndiceByQuotes: function () {
            var dataFilterIndice = { ...this.dataFilter }
            // dataFilter.car = 'all'
            // dataFilter.grupo = 'A'
            // store.dispatch("procloud/fetchIndiceByQuotes", dataFilter).then(() => {
            //     this.procloud.benchkmarking_indice_quotes.companies.forEach(element => {
            //         arrayQuotesValueD.push(element.coef_company)
            //     });
            //     this.quotes_statistics_values.push(arrayQuotesValueD)
            //     // this.quotes_statistics_values.sort((a, b) => a[0] - b[0]);
            // })
            // var arrayQuotesValueA = ['A']
            this.quotes_statistics_values = [];
            this.quotes_statistics_companies = [];
            store.dispatch("procloud/fetchIndiceByQuotes", dataFilterIndice).then(() => {
                this.quotes_statistics_companies.push(dataFilterIndice.fecha)
                this.procloud.benchkmarking_indice_quotes[0].resultados.forEach(element => {
                    this.quotes_statistics_companies.push(element.company_name)
                    // arrayQuotesValueA.push(element.coef_company)
                });
                this.quotes_statistics_values = this.procloud.benchkmarking_indice_quotes;
            })


        },
        getHistorical: function (event, type) {
            this.getResultados = false;
            this.configuration_result_historical = [];
            this.car_result_history = [];
            this.ipc_result_history = [];

            // Función helper para parsear fecha del formato 'month-yy' a Date
            const parseFilterDate = (filterDate) => {
                const [month, year] = filterDate.split('-');
                const monthNames = {
                    'january': 0, 'february': 1, 'march': 2, 'april': 3, 'may': 4, 'june': 5,
                    'july': 6, 'august': 7, 'september': 8, 'october': 9, 'november': 10, 'dicember': 11
                };
                return new Date(20 + year, monthNames[month], 1);
            };

            // Función helper para formatear Date a string en español
            const formatMonthYear = (date) => {
                const monthNames = [
                    'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
                    'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'
                ];
                return `${monthNames[date.getMonth()]} ${date.getFullYear().toString().slice(2)}`;
            };

            // Función helper para convertir Date a formato filter (e.g., 'january-24')
            const dateToFilterFormat = (date) => {
                const monthNames = [
                    'january', 'february', 'march', 'april', 'may', 'june',
                    'july', 'august', 'september', 'october', 'november', 'dicember'
                ];
                return `${monthNames[date.getMonth()]}-${date.getFullYear().toString().slice(2)}`;
            };

            // Obtener fecha actual del filtro
            const currentDate = parseFilterDate(this.dataFilter.fecha);

            // Generar array de los últimos 5 meses
            const months = Array.from({ length: 5 }, (_, i) => {
                const date = new Date(currentDate);
                date.setMonth(currentDate.getMonth() - (4 - i));
                return date;
            });

            // Actualizar categorías del eje X
            this.chart[0].bodyData.xaxis.categories = months.map(date => formatMonthYear(date));

            // Función para obtener historial de autos
            const fetchCarHistory = async () => {
                const dataFilter = { ...this.dataFilter };
                dataFilter.fecha = dateToFilterFormat(months[3]); // Usar el penúltimo mes

                await store.dispatch("procloud/fetchCarsHistory", dataFilter);
                this.car_history = this.procloud.benchkmarking_cars_history;

                // Procesar datos de autos
                this.car_result_history = [
                    {
                        name: 'Suma Asegurada',
                        data: months.map(date => {
                            const carData = this.car_history.find(
                                car => car.year === date.getFullYear().toString() &&
                                    car.month === (date.getMonth() + 1).toString()
                            );
                            return carData ? (carData.sumaasegurada / 10000).toFixed(2) : 0;
                        })
                    }
                ];

                // Procesar datos IPC
                this.processIPCData(months);
            };

            // Función para obtener resultados históricos
            const fetchHistoricalResults = async () => {
                const results = [];
                const dataFilter = { ...this.dataFilter };

                // Obtener datos para cada mes, empezando desde el más reciente
                for (let i = months.length - 1; i >= 0; i--) {
                    dataFilter.fecha = dateToFilterFormat(months[i]);
                    await store.dispatch("procloud/fetchConfigurationResult", dataFilter);
                    results.push(this.procloud.benchkmarking_configuration_result);
                }

                this.configuration_result_historical = results;
                this.showHistoricalGraph();
            };

            // Ejecutar las consultas
            Promise.all([
                fetchCarHistory(),
                fetchHistoricalResults()
            ]).catch(error => {
                console.error('Error fetching historical data:', error);
            });
        },
        // Función helper para procesar datos IPC
        processIPCData: function (months) {
            this.car_result_history.push({ name: 'IPC', data: [] });
            let ipcOriginal = 0;

            months.forEach((date, index) => {
                const ipcData = this.ipc_history.find(
                    ipc => ipc.year === date.getFullYear().toString() &&
                        ipc.month === (date.getMonth() + 1).toString()
                );

                if (index === 0) {
                    ipcOriginal = ipcData ? ipcData.amount * 500 : 0;
                    this.car_result_history[1].data.push((ipcOriginal).toFixed(2));
                } else {
                    if (ipcData) {
                        ipcOriginal = ipcOriginal * ipcData.amount;
                        this.car_result_history[1].data.push((ipcOriginal).toFixed(2));
                    }
                }
            });
        },
        showHistoricalGraph: function () {

            console.log("HISTORICAL")
            console.log(this.configuration_result_historical)
            this.chart[0].bodyData.series = [];

            var companies = [];


            companies.push(this.car_result_history[0])
            companies.push(this.car_result_history[1])

            for (let index = 0; index < this.configuration_result_historical.length; index++) {
                for (let i = 0; i < this.configuration_result_historical[index].length; i++) {
                    var agregarComp = true;
                    for (let j = 0; j < companies.length; j++) {
                        if (this.configuration_result_historical[index][i].company_name == companies[j].name) {
                            agregarComp = false;
                        }
                    }
                    if (agregarComp) {
                        companies.push({ name: this.configuration_result_historical[index][i].company_name, data: [] })
                    }
                }
            }

            console.log(companies)
            this.configuration_result_historical.reverse()
            for (let index = 0; index < this.configuration_result_historical.length; index++) {
                // console.log(this.configuration_result_historical[index])
                for (let i = 0; i < this.configuration_result_historical[index].length; i++) {
                    for (let j = 0; j < companies.length; j++) {
                        if (this.configuration_result_historical[index][i].company_name == companies[j].name) {
                            companies[j].data.push((this.configuration_result_historical[index][i].premio_mensual / 80 + 200 / 2).toFixed())
                        }
                    }
                    // console.log(companies);
                    // for (let y = 0; y < companies.length; y++) {
                    //     console.log(companies[y])
                    //     console.log(companies[y].name)
                    //     console.log(companies[y].data)
                    //     if (companies[y].data.length == 0) {
                    //         //companies[y].data.push(0)
                    //     }
                    // }
                    // return
                }
                for (let y = 0; y < companies.length; y++) {
                    // console.log(companies[y])
                    console.log(companies[y].name)
                    console.log(index)
                    console.log(companies[y].data.length)
                    if (companies[y].data.length == index) {
                        companies[y].data.push(0)
                    }
                }
                console.log(companies)
                if (index == 2) {
                    // return
                }
            }




            this.chart[0].bodyData.series = companies;


            // series: [{
            //       name: 'series1',
            //       data: [31, 40, 28, 51, 42, 109, 100]
            //     }, {
            //       name: 'series2',
            //       data: [11, 32, 45, 32, 34, 52, 41]
            //     }],


            // for (let index = 0; index < this.configuration_result.companies.length; index++) {
            //     const element = this.configuration_result.companies[index];
            //     if (element.quote_total > 0) {
            //         var valorAuto = element.quote_total;
            //         if (element.has_price == false) {
            //             valorAuto = element.quote_total * this.configuration_result.locations.length;
            //         }
            //         var obj = { company: element.company_name, value: valorAuto };
            //         if (element.indice_premio > 1) {
            //             var obj = { company: element.company_name, value: valorAuto, grather: true, color: am4core.color('#bdffcd') };
            //         }else if (element.indice_premio < 1) {
            //             var obj = { company: element.company_name, value: valorAuto, grather: true, color: am4core.color('#bdffcd') };
            //             //var obj = { company: element.company_name, value: valorAuto, cheaper: true, color: am4core.color('#ffbdbd') };
            //         }else if (element.indice_premio == 1) {
            //             var obj = { company: element.company_name, value: valorAuto, grather: true, color: am4core.color('#bdffcd') };
            //             //var obj = { company: element.company_name, value: valorAuto, equal: true, color: am4core.color('#acdcff') };
            //         }
            //         if (element.company_name == this.dataFilter.companyReference) {

            //             obj = {
            //                 company: element.company_name,
            //                 value: valorAuto,
            //                 strokeWidth: 1,
            //                 columnDash: "5,5",
            //                 fillOpacity: 0.2 ,
            //                 "color": am4core.color("#acdcff")
            //             };
            //         }
            //         this.chart[0].bodyData.data.push(obj);
            //     }
            // }
            this.getResultados = true;
        },
        list: function () {
            let contactData = {
                "status": "published",
                "first_name": this.first_name,
                "last_name": this.last_name,
                "whatsapp": this.whatsapp,
                "owner": 2
            }

            this.$store.dispatch('wookieContact/list', contactData.owner)
                .then((res) => {
                    this.contactList = res;
                    var selectedItems = this.selectedItems;
                    setTimeout(function () {
                        this.dataTable = $('#datatable').DataTable({
                            select: {
                                style: 'multi'
                            },
                            autoFill: {
                                update: true
                            }, language: {
                                url: '//cdn.datatables.net/plug-ins/1.11.5/i18n/es-ES.json'
                            },
                            buttons: [
                                'selectAll',
                                'selectNone'
                            ]
                        });

                        dataTable.on('select', function (e, dt, type, indexes) {
                            var rowData = dataTable.rows(indexes).data().toArray();
                            var thisID = rowData[0]['DT_RowId'].split('-')[1];
                            selectedItems.push(thisID);
                            console.log(selectedItems);

                            //events.prepend( '<div><b>'+type+' selection</b> - '+JSON.stringify( rowData )+'</div>' );
                        })
                            .on('deselect', function (e, dt, type, indexes) {
                                var rowData = dataTable.rows(indexes).data().toArray();
                                var thisID = rowData[0]['DT_RowId'].split('-')[1];
                                const index = selectedItems.indexOf(thisID);
                                selectedItems.splice(index, 1);

                                console.log(selectedItems);
                                //events.prepend( '<div><b>'+type+' <i>de</i>selection</b> - '+JSON.stringify( rowData )+'</div>' );
                            });
                    }, 1500);
                })
                .catch(err => console.log(err))
        }
    }
}
</script>
<style scoped>
span.select-info {
    display: none !important;
}

.warning-delete {
    display: inline-block;
    margin-bottom: 1.5rem !important;
    padding: 10px 20px;
    text-align: center;
    vertical-align: middle;
}

#list-agenda {
    background: yellow;
}

.a {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    height: 25px;
    width: 31px;

}

.b {
    position: relative;
    bottom: 5px;
    right: 1px;
}

td.cheaper {
    color: #535f6b;
    background: #ffbdbd;
}

td.greater {
    color: #535f6b;
    background: #bdffcd;
}

td.equal {
    background: #acdcff;
}

.form-multiple .vue-treeselect__control {
    background: none !important;
    border: 0;
    padding: 0;
}

#datatable-new th {
    text-align: center;
    font-size: 15px;
}

#datatable-new .table td {
    padding: 0;
    max-width: 100px;
}

#datatable-new .table td span {
    display: block;
    max-width: 80px;
    padding: 0.75rem;
}

#datatable-new td span.greater {
    background: #ffbdbd;
}

#datatable-new td span.equal {
    background: #acdcff;
}

#datatable-new td span.cheaper {
    background: #bdffcd;
}
</style>

<style>
.form-multiple {
    padding-top: 0;
}

.vue-treeselect__control {
    height: 44px !important;
    background: #fafbfe !important;
    border: 1px solid #f5f5f6 !important;
    background: none !important;
    border: 0;
    padding: 0;
}

.vue-treeselect__placeholder {
    padding: 5px;
    color: #535f6b !important;
}


#datatable-new th {
    text-align: center;
    font-size: 15px;
}

#datatable-new td {
    padding: 0.25rem;
}

#datatable-new td span {
    display: block;
    padding: 0.50rem;
    text-align: center;
}

#datatable-new .table td span.greater {
    background: #ffbdbd;
}

#datatable-new .table td span.equal {
    background: #acdcff;
}

#datatable-new .table td span.cheaper {
    background: #bdffcd;
}
</style>